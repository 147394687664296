import { graphql } from "~/gql";

export const createChallengeMutation = graphql(`
  mutation createChallenge($input: ChallengeInput!) {
    createChallenge(input: $input) {
      challenges {
        slug
        challengeName
      }
      status {
        success
        identifier
        message
      }
    }
  }
`);

export const getChallengeBySlugQuery = graphql(`
  query getChallengeBySlug($input: ChallengeSlugInput!) {
    getChallengeBySlug(input: $input) {
      status {
        success
        identifier
        message
      }
      challenge {
        slug
        challengeName
        challengeDescription
        challengeReward
        challengeType
        challengeVideos {
          id
          videoSource
          aspectMode
          downloadUrl
        }
        challengedUser {
          username
          displayName
          avatar
        }
        expirationDate
        id
        owner {
          username
          displayName
          avatar
        }
        status
        tags {
          name
        }
      }
    }
  }
`);

export const getChallengesByStatusQuery = graphql(`
  query getChallengesByStatus($input: ChallengesByStatusInput!) {
    getChallengesByStatus(input: $input) {
      status {
        success
        identifier
        message
      }
      challenges {
        slug
        status
        challengeName
        challengeReward
        expirationDate
        owner {
          avatar
          username
          displayName
        }
        challengedUser {
          avatar
          displayName
        }
      }
    }
  }
`);

export const getChallengesCountByStatusQuery = graphql(`
  query getChallengesCountByStatus($input: ChallengesByStatusInput!) {
    getChallengesByStatus(input: $input) {
      status {
        success
        identifier
        message
      }
      totalChallengesCount
    }
  }
`);

export const updateChallengeStatusMutation = graphql(`
  mutation updateChallengeStatus($input: ChallengeUpdateStatusInput!) {
    updateChallengeStatus(input: $input) {
      status {
        success
        identifier
        message
      }
      challenge {
        slug
        status
        owner {
          username
        }
        challengedUser {
          username
        }
      }
    }
  }
`);

export const updateChallengeMutation = graphql(`
  mutation updateChallenge($input: UpdateChallengeInput!) {
    updateChallenge(input: $input) {
      status {
        success
        identifier
        message
      }
    }
  }
`);

export const createChallengeVideoMutation = graphql(`
  mutation createChallengeVideo($input: ChallengeVideoInput!) {
    createChallengeVideo(input: $input) {
      id
      videoSource
      status {
        success
        message
      }
    }
  }
`);
